/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "From the day we started working on Glovebox at Dashboard, we were always asked to embed third-party chat systems onto our client's websites. Most looked and functioned like garbage, and I always wanted to build a better version. After going to NADA (the largest dealer conference in North America), I started building a call tracking software using the Octane framework, which only lasted a short time. I soon pivoted to building a full-blown chat app with a little twist. Imagine, instead of having to be physically on the computer to see and respond to chatting visitors on your site, you were able to forward the message to your phone via SMS and MMS. I used the Twilio API to forward messages to the dealer or the user if their cell number was in the system. I used NodeJS and Socket.io to provide real-time chat and the Twilio NodeJS module to interact with Twilio. However, there were a few hardships along the way. One of the most challenging features I built was when or if to forward the message. There were a couple of iterations on this, but I created something elegant that solved chat forwarding and, as a bonus, made everything easier to read."), "\n", React.createElement(_components.p, null, "I teamed up with a colleague to assist with the interface and design of the admin area and the chat on a dealer's site. Everything looked exceptionally crisp, and it was straightforward to use. We built it into the Octane platform to provide seamless integration with the other products using the platform. While this never went to production, it was a great side project built from scratch and completed a beta version."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
